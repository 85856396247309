<template>
    <a-layout class="layout" >
    
      <a-layout-header style="background-color:aliceblue">
        <div style="display:flex; justify-content:center; align-items: center; background-color:white; ">
        <a-space>  
        <div class="logo">
          <a-image src="./assets/others/h2technik_small.jpg" :preview="false"/>
        </div>
         <a-menu
          theme="light"
          mode="horizontal"
          :style="{ lineHeight:'60px'}"
        >
          <a-menu-item key="1" @click="loadHomePage()">Home</a-menu-item>
      
                   
          <a-menu-item key="4" @click="loadDTUModules()">Data Transceiver</a-menu-item>
          <a-menu-item key="3" @click="loadIoTModules()">IoT Module</a-menu-item>  
          <a-menu-item key="5"  @click="loadCableAssemble()"><label style="color:red; font-weight: bold;">Pigtail-50 Assemble</label></a-menu-item>
          <a-menu-item key="8"  @click="loadConnectorAssemble()"><label style="color:red; font-weight: bold;">conXmate-50 Assemble</label></a-menu-item>

          <a-menu-item key="2" ><a href="https://www.h-2technik.com/webshop/new_shop/a/public_html/index.php" target="_blank">
             <label style="color:rgb(0,255,0); font-weight: bold;">Webshop</label></a></a-menu-item>
          <a-menu-item key="6"  @click="loadContactUs()">About US&Contact</a-menu-item>
          
          <a-menu-item key="7"  @click="loadImprint()">Imprint</a-menu-item>
         </a-menu>
        </a-space>
       
       </div>
       
      </a-layout-header>

      
    <a-layout-content id="sub-vue-component" >
      <a-image
         :width="1200"
         :preview="false"
         src="./assets/AI_images/embeddedworld.png"
         style="margin-top: 5px"/>
        <a-divider style="height: 1px; background-color: #7cb305; margin-top: 0px" />
        
        <div class="breadcrumb-container" id="showpoint">
         <a-breadcrumb >
           <a-breadcrumb-item href="" @click="loadHomePage()">
            <home-outlined />
           </a-breadcrumb-item>
           <a-breadcrumb-item >
             <a href="https://www.h-2technik.com/webshop/new_shop/a/public_html/index.php" target="_blank"><shopping-cart-outlined /></a>
           </a-breadcrumb-item>
           <a-breadcrumb-item href="" @click="loadIoTModules()">
            <radar-chart-outlined />
           </a-breadcrumb-item>
           <a-breadcrumb-item href="" @click="loadDTUModules()">
            <wifi-outlined />
           </a-breadcrumb-item>
           
           <a-breadcrumb-item href="" @click="loadCableAssemble()">
            <line-outlined />
           </a-breadcrumb-item>

           <a-breadcrumb-item href="" @click="loadConnectorAssemble()">
             X
           </a-breadcrumb-item>

           <a-breadcrumb-item href="" @click="loadContactUs()">
            <contacts-outlined />
          </a-breadcrumb-item>
         </a-breadcrumb>
        </div>
      <component :is="currentComponent" id="scrolltarget"></component>

      <div v-if="showBackToTopButton" class="back-to-top-button" @click="scrollToTop">
        ↑ Back to Top
      </div>
    </a-layout-content>
   
   

    <a-divider style="height: 1px; background-color: #7cb305" />
     <a-layout-footer style="text-align: center">
      <a-space>
        <p>H-2 Technik @2024 Created by H-2 Technik supported by Ant Design</p>
        <p><strong><a @click="loadPrivatePolicy()">Privacy Policy</a></strong></p>

      </a-space>
    </a-layout-footer>
    </a-layout>
   
 
</template>


<script>
import ProductSection from './ProductSection.vue';
import CableAssemble from './CableAssemble.vue';
import ConnectorAssemble from './ConnectorAssemble.vue';
import ContactUS from './ContactUs.vue';
import IoTModules from './IoTModules.vue';
import DTUModules from './DTUModules.vue';
import ImprintInfo from './ImprintInfo.vue';
import PrivatePolicy from './PrivatePolicy.vue';
import { ConnectorDB, CoaxialDB, AdapterDB, ProductsDBInstance } from "@/DataModel/ProductDBDataStruct";
import { markRaw } from 'vue';

import { HomeOutlined, WifiOutlined, RadarChartOutlined, 
         ShoppingCartOutlined, LineOutlined, 
         ContactsOutlined} from '@ant-design/icons-vue';




export default {
  name: 'MainContainer',
  props: {
    msg: String,
  },
  components: {
    HomeOutlined,
    WifiOutlined,
    RadarChartOutlined,
    ShoppingCartOutlined,
    LineOutlined,
    ContactsOutlined,

    ProductSection: markRaw(ProductSection),
    CableAssemble:markRaw(CableAssemble),
    ConnectorAssemble: markRaw(ConnectorAssemble),
    ContactUS:markRaw(ContactUS),
    IoTModules: markRaw(IoTModules),
    DTUModules:markRaw(DTUModules),
    ImprintInfo: markRaw(ImprintInfo),
    PrivatePolicy:markRaw(PrivatePolicy),
  },
 
  data(){
    return {currentComponent: null,
            showBackToTopButton: false,
    };
  },

  mounted() {
    //this.loadHomePage(); 
    //window.addEventListener("scroll", this.handleScroll);



    //04.06.2024 fetch raw connector types from RawConnectorType
    ProductsDBInstance.fetchData(0,"")
                        .then(function(result){
                          var data = JSON.parse(result);
                          ProductsDBInstance.rawconntypes = [];
                          for (var i2 = 0; i2<data.length; i2++){
                             var name = data[i2].Name;
                             var convert2 = data[i2].convert2;
                             ProductsDBInstance.rawconntypes.push(new AdapterDB(name, convert2));
                           }
                           
                          })
                          .catch(function (){
                          });

    /***********18.01.2024*********/
    //为了能保证 在打开 pigtail50_assemble 页面时 来自服务器的数据能主备好， 所以在打开主页时就要访问服务器，并将数据保存在 ProductsDBInstance中
    ProductsDBInstance.fetchData(1,"")
                         .then(function(result){
                            var data = JSON.parse(result);
                            ProductsDBInstance.connectors = [];
                              //console.log(result);
                              for (var i = 0; i<data.length; i++){
                                var label = data[i].label;
                                var tech_data = data[i].tech_data;
                                var suitablecable = data[i].SuitableCable;
                                ProductsDBInstance.connectors.push(new ConnectorDB(label, tech_data, suitablecable));
                              }
                          })
                          .catch(function (){
                            //console.log("error fetching connector");
                          });
      
    //this.productionsDB.fetchData(2)
    ProductsDBInstance.fetchData(2,"")
                         .then(function(result){
                            var data = JSON.parse(result);
                            ProductsDBInstance.cables = [];
                            for (var i1 = 0; i1<data.length; i1++){
                                var label1 = data[i1].label;
                                var tech_data1 = data[i1].tech_data;
                                ProductsDBInstance.cables.push(new CoaxialDB(label1, tech_data1));
                            }
                          })
                          .catch(function (){
                          });

    //this.productionsDB.fetchData(3)
    ProductsDBInstance.fetchData(3,"")
                        .then(function(result){
                          var data = JSON.parse(result);
                          for (var i2 = 0; i2<data.length; i2++){
                             var label2 = data[i2].label;
                             ProductsDBInstance.StandardProducts.push(label2);
                           }
                          })
                          .catch(function (){
                          });

    
   
    /***********18.01.2024*********/
    setTimeout(() => { 
      this.loadHomePage(); 
      window.addEventListener("scroll", this.handleScroll);

          },
    1000); 

    this.scrollToElement("#scrolltarget");

  },

  methods: {

    scrollToShowpoint() {
       // Rufen Sie das DOM-Element mit der ID "showpoint" ab
       const showpointElement = document.getElementById("showpoint");
      if (showpointElement) {
        // Scrollen Sie zur Position des Elements
        showpointElement.scrollIntoView({ behavior: "smooth" });
      }
    },
     
      loadHomePage(){
        this.currentComponent = ProductSection;
        
      },
      loadCableAssemble(){
        this.currentComponent = CableAssemble;
        this.scrollToShowpoint();
      },

      loadConnectorAssemble(){
        this.currentComponent = ConnectorAssemble;
        this.scrollToShowpoint();
      },

      loadContactUs(){
        this.currentComponent = ContactUS;
        this.scrollToShowpoint();
      },

      loadIoTModules(){
        this.currentComponent = IoTModules;
        this.scrollToShowpoint();
      },

      loadDTUModules(){
        this.currentComponent = DTUModules;
        this.scrollToShowpoint();
      },

      loadImprint(){
        this.currentComponent = ImprintInfo;
        this.scrollToShowpoint();
      },

      loadPrivatePolicy(){
        //console.log("open private policy");
        this.currentComponent = PrivatePolicy;
        this.scrollToShowpoint();
      },

      handleScroll() {
        this.showBackToTopButton = window.scrollY > 300; // Zeige den Knopf, wenn der Benutzer 300px nach unten gescrollt ist
      },

      scrollToTop() {
        window.scrollTo({
         top: 0,
         behavior: "smooth" // Sanftes Scrollen
        });
       },

       scrollToElement(selector) {
         setTimeout(() => {
        const element = document.querySelector(selector);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 2000); // 停留两秒后滚动
    },

      
    },


   beforeUnmount() {
     window.removeEventListener("scroll", this.handleScroll);
   },
}
</script>

<style scoped >
  .back-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 200px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.breadcrumb-container {
  display:flex;
  justify-content: center;
  margin: 5px; /* Fügt Abstand hinzu */
}

</style>
